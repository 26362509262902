import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/organisms/Layout';

import ExploreMoreWorkBlock from '../../src/components/organisms/ExploreMoreWorkBlock'
import HeadingTextBlock from '../../src/components/organisms/HeadingTextBlock'
import MainBanner from '../../src/components/organisms/MainBanner'
import SingleImageBlock from '../../src/components/organisms/SingleImageBlock'
import ThreeColumnTextImageBlock from '../../src/components/organisms/ThreeColumnTextImageBlock'
import TwoColumnImageBlock from '../../src/components/organisms/TwoColumnImageBlock'

const PageTemplate = (pageProps) => {
  const accessor = Object.keys(pageProps.data)[0];

  let components;

    components = pageProps.data.wpPortfolioItem['portfolioPageModules']['components'] || []
  
  components = components.map((component) => {
    return {
      name: component.__typename.split('_').pop(),
      data: component,
    };
  });

  return (
    <Layout
      title={pageProps.data[accessor].title}
      url={pageProps.data[accessor].uri}
      footerData={pageProps.data[accessor].footerFields}
      seo={pageProps.data[accessor].seo}
    >
      {components.map((component, index) => {

      if (component.name == 'ExploreMoreWorkBlock') {
        return <ExploreMoreWorkBlock {...component.data} key={index} />
      }
    

      if (component.name == 'HeadingTextBlock') {
        return <HeadingTextBlock {...component.data} key={index} />
      }
    

      if (component.name == 'MainBanner') {
        return <MainBanner {...component.data} key={index} />
      }
    

      if (component.name == 'SingleImageBlock') {
        return <SingleImageBlock {...component.data} key={index} />
      }
    

      if (component.name == 'ThreeColumnTextImageBlock') {
        return <ThreeColumnTextImageBlock {...component.data} key={index} />
      }
    

      if (component.name == 'TwoColumnImageBlock') {
        return <TwoColumnImageBlock {...component.data} key={index} />
      }
    
        return <div>Error: The component {component.name} was not found</div>;
      })}
    </Layout>
  );
};

export default PageTemplate;


    export const query = graphql`
      query PageQuery556($id: String!) {
        wpPortfolioItem(id: {eq: $id}) {
          title
          uri
          
    portfolioPageModules {
      components {
        __typename
         
 
        ... on WpPortfolioItem_Portfoliopagemodules_Components_ExploreMoreWorkBlock {
          ...PortfolioItem_ExploreMoreWorkBlock
        }
       
 
        ... on WpPortfolioItem_Portfoliopagemodules_Components_HeadingTextBlock {
          ...PortfolioItem_HeadingTextBlock
        }
       
 
        ... on WpPortfolioItem_Portfoliopagemodules_Components_MainBanner {
          ...PortfolioItem_MainBanner
        }
       
 
        ... on WpPortfolioItem_Portfoliopagemodules_Components_SingleImageBlock {
          ...PortfolioItem_SingleImageBlock
        }
       
 
        ... on WpPortfolioItem_Portfoliopagemodules_Components_ThreeColumnTextImageBlock {
          ...PortfolioItem_ThreeColumnTextImageBlock
        }
       
 
        ... on WpPortfolioItem_Portfoliopagemodules_Components_TwoColumnImageBlock {
          ...PortfolioItem_TwoColumnImageBlock
        }
      
      }
    }
  
          seo {
            title
            metaDesc
            canonical
            schema {
              raw
            }
          }

          footerFields {
            ... on WpPortfolioItem_Footerfields {
              backgroundColor
              footerCtaHeading
              overrideCtaLink {
                title
                url
              }
              hideCtaBlock
            }
          }
        }
      }
    `
  
